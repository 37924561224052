define('accounting/unformat', ['exports', 'accounting/settings'], function (exports, settings) {

  'use strict';

  function unformat(value, decimal) {
    // Recursively unformat arrays:
    if (Array.isArray(value)) {
      return value.map(function (val) {
        return unformat(val, decimal);
      });
    }

    // Fails silently (need decent errors):
    value = value || 0;

    // Return the value as-is if it's already a number:
    if (typeof value === "number") {
      return value;
    }

    // Default decimal point comes from settings, but could be set to eg. "," in opts:
    decimal = decimal || settings.number.decimal;

    // Build regex to strip out everything except digits, decimal point and minus sign:
    var regex = new RegExp("[^0-9-" + decimal + "]", ["g"]);
    var unformatted = parseFloat(("" + value).replace(/\((.*)\)/, "-$1") // replace bracketed values with negatives
    .replace(regex, '') // strip out any cruft
    .replace(decimal, '.') // make sure decimal point is standard
    );

    // This will fail silently which may cause trouble, let's wait and see:
    return !isNaN(unformatted) ? unformatted : 0;
  }

  exports['default'] = unformat;

});