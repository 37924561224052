define('debounce-query-params/mixins/debounce-query-params', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    /**
    Setup the controller for debouncing query param by calling
    setupDebounceQueryParams
     @method init
    */
    init: function init() {
      this._super();
      this.setupDebounceQueryParams();
    },

    /**
    Update the underneath property hence the query param
     @method updateQueryParam
    @param param {String} the name of the property to update
    @param value {String} the new value of the property to update
    */
    updateQueryParam: function updateQueryParam(param, value) {
      this.set(param, value);
    },

    /**
    Decounce the query param update
     @method paramUpdate
    @param context {Object} the name of the property to update
    @param debounceParamName {String} the name of the property to update
    in the format debounce_paramName
    */
    paramUpdate: function paramUpdate(context, debounceParamName) {
      var originalParamName = debounceParamName.substr(9, debounceParamName.length);
      var originalParamValue = this.get(debounceParamName);

      Ember['default'].run.debounce(this, this.updateQueryParam, originalParamName, originalParamValue, this.get('debounceQueryParams.' + originalParamName));
    },

    /**
    Create an observer for each of the properties in the debounceQueryParams obejct
     @method setupDebounceQueryParams
    */
    setupDebounceQueryParams: function setupDebounceQueryParams() {
      var _this = this;

      var debounceQueryParams = this.get('debounceQueryParams');

      var _loop = function (param) {
        Ember['default'].assert(_this.get(param) != null, param + ' must be in queryParams');

        Ember['default'].run.schedule('actions', _this, function () {
          this.set('debounce_' + param, this.get(param));

          this.addObserver('debounce_' + param, this, 'paramUpdate');
        });
      };

      for (var param in debounceQueryParams) {
        _loop(param);
      }
    }
  });

});