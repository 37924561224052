define('accounting/index', ['exports', 'accounting/format', 'accounting/format-column', 'accounting/format-money', 'accounting/format-number', 'accounting/parse', 'accounting/settings', 'accounting/to-fixed', 'accounting/unformat', 'accounting/version'], function (exports, format, formatColumn, formatMoney, formatNumber, parse, settings, toFixed, unformat, version) {

  'use strict';

  exports['default'] = {
    format: format['default'],
    formatColumn: formatColumn['default'],
    formatMoney: formatMoney['default'],
    formatNumber: formatNumber['default'],
    parse: parse['default'],
    settings: settings['default'],
    toFixed: toFixed['default'],
    unformat: unformat['default'],
    version: version['default']
  };

  exports.format = format['default'];
  exports.formatColumn = formatColumn['default'];
  exports.formatMoney = formatMoney['default'];
  exports.formatNumber = formatNumber['default'];
  exports.parse = parse['default'];
  exports.settings = settings['default'];
  exports.toFixed = toFixed['default'];
  exports.unformat = unformat['default'];
  exports.version = version['default'];

});