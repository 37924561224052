define('ember-inputmask/components/number-input', ['exports', 'ember', 'ember-inputmask/components/input-mask'], function (exports, Ember, InputMaskComponent) {

  'use strict';

  exports['default'] = InputMaskComponent['default'].extend({
    mask: 'integer',

    // Default options
    decimal: false,
    group: false,
    separator: ',',
    radix: '.',
    groupSize: '3',

    updateMask: function updateMask() {
      this.setProperties({
        'options.autoGroup': this.get('group'),
        'options.groupSeparator': this.get('separator'),
        'options.radixPoint': this.get('radix'),
        'options.groupSize': this.get('groupSize')
      });

      if (this.get('decimal') === true) {
        this.set('mask', 'decimal');
        this.set('options.digits', 2);
      } else if (this.get('decimal')) {
        this.set('mask', 'decimal');
        this.set('options.digits', this.get('decimal'));
      }

      this._super();
    },

    _maskShouldChange: Ember['default'].observer('mask', 'group', 'decimal', 'separator', 'radix', 'groupSize', function () {
      Ember['default'].run.once(this, 'updateMask');
    })
  });

});