define('ember-searchable-select/templates/components/searchable-select', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","searchable-select-get",[["get","_selected",["loc",[null,[9,28],[9,37]]]],["get","optionLabelKey",["loc",[null,[9,38],[9,52]]]]],[],["loc",[null,[9,4],[9,54]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 4
              },
              "end": {
                "line": 22,
                "column": 4
              }
            },
            "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","Searchable-select__selected-pill");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","Searchable-select__selected-pill-clear");
            var el3 = dom.createTextNode("\n          ×\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(element4, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element4,1,1);
            morphs[1] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [
            ["inline","searchable-select-get",[["get","selectedOption",["loc",[null,[15,32],[15,46]]]],["get","optionLabelKey",["loc",[null,[15,47],[15,61]]]]],[],["loc",[null,[15,8],[15,63]]]],
            ["element","action",["removeOption",["get","selectedOption",["loc",[null,[18,34],[18,48]]]]],["bubbles",false],["loc",[null,[18,10],[18,64]]]]
          ],
          locals: ["selectedOption"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 2
            },
            "end": {
              "line": 23,
              "column": 2
            }
          },
          "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","_selected",["loc",[null,[13,12],[13,21]]]]],[],0,null,["loc",[null,[13,4],[22,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 2
            },
            "end": {
              "line": 27,
              "column": 2
            }
          },
          "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","prompt",["loc",[null,[26,4],[26,14]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 4
              },
              "end": {
                "line": 52,
                "column": 4
              }
            },
            "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","Searchable-select__clear");
            dom.setAttribute(el1,"tabindex","-1");
            dom.setAttribute(el1,"data-enter-key-action","clear");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","Searchable-select__icon Searchable-select__icon--clear");
            var el3 = dom.createTextNode("\n          ×\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1,3,3);
            return morphs;
          },
          statements: [
            ["element","action",["clear"],["bubbles",false],["loc",[null,[46,8],[46,40]]]],
            ["content","clearLabel",["loc",[null,[50,8],[50,22]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 4
              },
              "end": {
                "line": 61,
                "column": 4
              }
            },
            "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","Searchable-select__add-new");
            dom.setAttribute(el1,"tabindex","-1");
            dom.setAttribute(el1,"data-enter-key-action","addNew");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("em");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0,1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]),0,0);
            return morphs;
          },
          statements: [
            ["element","action",["addNew"],["bubbles",false],["loc",[null,[58,6],[58,39]]]],
            ["content","addLabel",["loc",[null,[59,6],[59,18]]]],
            ["content","_searchText",["loc",[null,[59,23],[59,38]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 4
              },
              "end": {
                "line": 67,
                "column": 4
              }
            },
            "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","Searchable-select__info");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["content","noResultsMessage",["loc",[null,[65,6],[65,26]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 4
              },
              "end": {
                "line": 102,
                "column": 4
              }
            },
            "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","Searchable-select__info");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment(" Loading SVG by Sam Herbert (@sherb), More @ http://goo.gl/7AJzbL ");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el2 = dom.createElement("svg");
            dom.setAttribute(el2,"width","12");
            dom.setAttribute(el2,"height","12");
            dom.setAttribute(el2,"viewBox","0 0 40 40");
            dom.setAttribute(el2,"xmlns","http://www.w3.org/2000/svg");
            dom.setAttribute(el2,"stroke","#9a9a9a");
            dom.setAttribute(el2,"class","Searchable-select__loader");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("g");
            dom.setAttribute(el3,"fill","none");
            dom.setAttribute(el3,"fill-rule","evenod  d");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("g");
            dom.setAttribute(el4,"transform","translate(2 2)");
            dom.setAttribute(el4,"stroke-width","4");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("circle");
            dom.setAttribute(el5,"stroke-opacity",".6");
            dom.setAttribute(el5,"cx","18");
            dom.setAttribute(el5,"cy","18");
            dom.setAttribute(el5,"r","18");
            dom.setAttribute(el5,"class","Searchable-select__loader-circle");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("path");
            dom.setAttribute(el5,"d","M36 18c0-9.94-8.06-18-18-18");
            dom.setAttribute(el5,"class","Searchable-select__loader-spinner");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("animateTransform");
            dom.setAttribute(el6,"attributeName","transform");
            dom.setAttribute(el6,"type","rotate");
            dom.setAttribute(el6,"from","0 18 18");
            dom.setAttribute(el6,"to","360 18 18");
            dom.setAttribute(el6,"dur","0.8s");
            dom.setAttribute(el6,"repeatCount","indefinite");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.setNamespace(null);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","Searchable-select__loader-text");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 5]),1,1);
            return morphs;
          },
          statements: [
            ["content","loadingMessage",["loc",[null,[99,10],[99,28]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.7",
              "loc": {
                "source": null,
                "start": {
                  "line": 106,
                  "column": 6
                },
                "end": {
                  "line": 114,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","searchable-select-option",[],["option",["subexpr","@mut",[["get","option",["loc",[null,[108,17],[108,23]]]]],[],[]],"selected",["subexpr","@mut",[["get","_selected",["loc",[null,[109,19],[109,28]]]]],[],[]],"searchText",["subexpr","@mut",[["get","_searchText",["loc",[null,[110,21],[110,32]]]]],[],[]],"optionLabelKey",["subexpr","@mut",[["get","optionLabelKey",["loc",[null,[111,25],[111,39]]]]],[],[]],"optionDisabledKey",["subexpr","@mut",[["get","optionDisabledKey",["loc",[null,[112,28],[112,45]]]]],[],[]],"select-item",["subexpr","action",["selectItem"],[],["loc",[null,[113,22],[113,43]]]]],["loc",[null,[107,8],[113,45]]]]
            ],
            locals: ["option"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 104,
                "column": 4
              },
              "end": {
                "line": 116,
                "column": 4
              }
            },
            "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","Searchable-select__options-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","_filteredContent",["loc",[null,[106,14],[106,30]]]]],[],0,null,["loc",[null,[106,6],[114,15]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 0
            },
            "end": {
              "line": 119,
              "column": 0
            }
          },
          "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","Searchable-select__menu");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","Searchable-select__search");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createElementMorph(element3);
          morphs[1] = dom.createMorphAt(element3,1,1);
          morphs[2] = dom.createMorphAt(element2,3,3);
          morphs[3] = dom.createMorphAt(element2,5,5);
          morphs[4] = dom.createMorphAt(element2,7,7);
          morphs[5] = dom.createMorphAt(element2,9,9);
          morphs[6] = dom.createMorphAt(element2,11,11);
          return morphs;
        },
        statements: [
          ["element","action",["noop"],["bubbles",false],["loc",[null,[32,43],[32,74]]]],
          ["inline","input",[],["class","Searchable-select__input","type","text","placeholder",["subexpr","@mut",[["get","searchPrompt",["loc",[null,[36,20],[36,32]]]]],[],[]],"key-up","updateSearch","tabindex",-1,"bubbles",false],["loc",[null,[33,6],[39,23]]]],
          ["block","if",[["get","_isShowingClear",["loc",[null,[42,10],[42,25]]]]],[],0,null,["loc",[null,[42,4],[52,11]]]],
          ["block","if",[["get","_isShowingAddNew",["loc",[null,[54,10],[54,26]]]]],[],1,null,["loc",[null,[54,4],[61,11]]]],
          ["block","if",[["get","_isShowingNoResultsMessage",["loc",[null,[63,10],[63,36]]]]],[],2,null,["loc",[null,[63,4],[67,11]]]],
          ["block","if",[["get","isLoading",["loc",[null,[69,10],[69,19]]]]],[],3,null,["loc",[null,[69,4],[102,11]]]],
          ["block","if",[["get","_hasResults",["loc",[null,[104,10],[104,21]]]]],[],4,null,["loc",[null,[104,4],[116,11]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 119,
            "column": 7
          }
        },
        "moduleName": "modules/ember-searchable-select/templates/components/searchable-select.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1,"href","#");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element6, 'class');
        morphs[1] = dom.createElementMorph(element6);
        morphs[2] = dom.createMorphAt(element6,1,1);
        morphs[3] = dom.createMorphAt(element6,3,3);
        morphs[4] = dom.createMorphAt(element6,5,5);
        morphs[5] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["Searchable-select__label\n    ",["subexpr","if",[["get","_isShowingMenu",["loc",[null,[3,9],[3,23]]]],"Searchable-select__label--menu-open"],[],["loc",[null,[3,4],[3,63]]]],"\n    ",["subexpr","if",[["get","multiple",["loc",[null,[4,9],[4,17]]]],"Searchable-select__label--multiple"],[],["loc",[null,[4,4],[4,56]]]],"\n    ",["subexpr","if",[["get","_hasMultipleSelection",["loc",[null,[5,9],[5,30]]]],"Searchable-select__label--multiple-selected"],[],["loc",[null,[5,4],[5,78]]]]]]],
        ["element","action",["toggleMenu"],[],["loc",[null,[6,2],[6,25]]]],
        ["block","if",[["get","_hasSingleSelection",["loc",[null,[8,8],[8,27]]]]],[],0,null,["loc",[null,[8,2],[10,9]]]],
        ["block","if",[["get","_hasMultipleSelection",["loc",[null,[12,8],[12,29]]]]],[],1,null,["loc",[null,[12,2],[23,9]]]],
        ["block","unless",[["get","_hasSelection",["loc",[null,[25,12],[25,25]]]]],[],2,null,["loc",[null,[25,2],[27,13]]]],
        ["block","if",[["get","_isShowingMenu",["loc",[null,[30,6],[30,20]]]]],[],3,null,["loc",[null,[30,0],[119,7]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});