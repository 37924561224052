var FilteredSubset = Ember.ArrayProxy.extend({
  init: function () {
    this._super.apply(this, arguments);

    var _getProperties = this.getProperties('filterByArgs', 'recordArray');

    var filterByArgs = _getProperties.filterByArgs;
    var recordArray = _getProperties.recordArray;
    var key = filterByArgs[0];

    var path = 'recordArray.@each.' + key;
    Ember.defineProperty(this, 'content', Ember.computed(path, function () {
      return this.filterBy.apply(recordArray, filterByArgs);
    }));
  }
});

export default FilteredSubset;