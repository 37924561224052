define('ember-truth-helpers/helpers/xor', ['exports', 'ember-truth-helpers/utils/truth-convert'], function (exports, truthConvert) {

  'use strict';

  exports.xorHelper = xorHelper;

  function xorHelper(params) {
    return truthConvert['default'](params[0]) !== truthConvert['default'](params[1]);
  }

});