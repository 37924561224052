define('ember-inputmask/components/zip-code-input', ['exports', 'ember', 'ember-inputmask/components/input-mask'], function (exports, Ember, InputMaskComponent) {

  'use strict';

  exports['default'] = InputMaskComponent['default'].extend({
    mask: '99999',

    fullCode: false,

    updateMask: function updateMask() {
      if (this.get('fullCode')) {
        this.set('mask', '99999[-9999]');
      } else {
        this.set('mask', '99999');
      }

      this._super();
    },

    _maskShouldChange: Ember['default'].observer('mask', 'fullCode', function () {
      Ember['default'].run.once(this, 'updateMask');
    })
  });

});