define('accounting/format-column', ['exports', 'accounting/settings', 'accounting/utils', 'accounting/format-number', 'accounting/unformat'], function (exports, settings, utils, formatNumber, unformat) {

  'use strict';

  function formatColumn(list, symbol, precision, thousand, decimal, format) {
    if (!list) {
      return [];
    }

    // Build options object from second param (if object) or all params, extending defaults:
    var opts = utils.defaults(utils.isObject(symbol) ? symbol : {
      symbol: symbol,
      precision: precision,
      thousand: thousand,
      decimal: decimal,
      format: format
    }, settings.currency);

    // Check format (returns object with pos, neg and zero), only need pos for now:
    var formats = utils.checkCurrencyFormat(opts.format);

    // Whether to pad at start of string or after currency symbol:
    var padAfterSymbol = formats.pos.indexOf("%s") < formats.pos.indexOf("%v");

    // Store value for the length of the longest string in the column:
    var maxLength = 0;

    // Format the list according to options, store the length of the longest string:
    var formatted = list.map(function (val) {
      if (Array.isArray(val)) {
        // Recursively format columns if list is a multi-dimensional array:
        return formatColumn(val, opts);
      } else {
        // Clean up the value
        val = unformat['default'](val);

        // Choose which format to use for this value (pos, neg or zero):
        var useFormat = val > 0 ? formats.pos : val < 0 ? formats.neg : formats.zero;

        // Format this value, push into formatted list and save the length:
        var fVal = useFormat.replace('%s', opts.symbol).replace('%v', formatNumber['default'](Math.abs(val), utils.checkPrecision(opts.precision), opts.thousand, opts.decimal));

        if (fVal.length > maxLength) {
          maxLength = fVal.length;
        }
        return fVal;
      }
    });

    // Pad each number in the list and send back the column of numbers:
    return formatted.map(function (val) {
      // Only if this is a string (not a nested array, which would have already been padded):
      if (typeof val === "string" && val.length < maxLength) {
        // Depending on symbol position, pad after symbol or at index 0:
        return padAfterSymbol ? val.replace(opts.symbol, opts.symbol + new Array(maxLength - val.length + 1).join(" ")) : new Array(maxLength - val.length + 1).join(" ") + val;
      }
      return val;
    });
  }

  exports['default'] = formatColumn;

});