define('ember-get-helper/helpers/get', ['exports', 'ember-get-helper/utils/get-stream'], function (exports, getStream) {

  'use strict';

  exports.getHelper = getHelper;

  function getHelper(params, hash, options, env) {
    if (arguments.length === 0) {
      return;
    }

    var view = env.data.view;
    var obj = params[0];
    var key = params[1];

    return getStream['default'](view, obj, key);
  }

  exports['default'] = getHelper;

});