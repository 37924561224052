define('accounting/format-money', ['exports', 'accounting/unformat', 'accounting/format-number', 'accounting/settings', 'accounting/utils'], function (exports, unformat, formatNumber, settings, utils) {

  'use strict';

  function formatMoney(number, symbol, precision, thousand, decimal, format) {
    // Resursively format arrays:
    if (Array.isArray(number)) {
      return number.map(function (val) {
        return formatMoney(val, symbol, precision, thousand, decimal, format);
      });
    }

    // Clean up number:
    number = unformat['default'](number);

    // Build options object from second param (if object) or all params, extending defaults:
    var opts = utils.defaults(utils.isObject(symbol) ? symbol : {
      symbol: symbol,
      precision: precision,
      thousand: thousand,
      decimal: decimal,
      format: format
    }, settings.currency);

    // Check format (returns object with pos, neg and zero):
    var formats = utils.checkCurrencyFormat(opts.format);

    // Choose which format to use for this value:
    var useFormat = number > 0 ? formats.pos : number < 0 ? formats.neg : formats.zero;

    // Return with currency symbol added:
    return useFormat.replace('%s', opts.symbol).replace('%v', formatNumber['default'](Math.abs(number), utils.checkPrecision(opts.precision), opts.thousand, opts.decimal));
  }

  exports['default'] = formatMoney;

});