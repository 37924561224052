define('ember-searchable-select/templates/components/searchable-select-option', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "modules/ember-searchable-select/templates/components/searchable-select-option.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","Searchable-select__icon Searchable-select__icon--check\n    Searchable-select__option-indicator--selected");
          var el2 = dom.createTextNode("\n    ✓\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 7
          }
        },
        "moduleName": "modules/ember-searchable-select/templates/components/searchable-select-option.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createMorphAt(element0,1,1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["get","isSelected",["loc",[null,[1,6],[1,16]]]]],[],0,null,["loc",[null,[1,0],[6,7]]]],
        ["attribute","class",["concat",["Searchable-select__option-label\n  ",["subexpr","if",[["get","isSelected",["loc",[null,[9,7],[9,17]]]],"Searchable-select__option-label--selected"],[],["loc",[null,[9,2],[9,63]]]]]]],
        ["inline","searchable-select-highlight-match",[["subexpr","searchable-select-get",[["get","option",["loc",[null,[11,27],[11,33]]]],["get","optionLabelKey",["loc",[null,[11,34],[11,48]]]]],[],["loc",[null,[11,4],[11,49]]]],["get","searchText",["loc",[null,[12,4],[12,14]]]]],[],["loc",[null,[10,2],[13,4]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});