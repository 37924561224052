define('accounting/to-fixed', ['exports', 'accounting/utils', 'accounting/settings', 'accounting/unformat'], function (exports, utils, settings, unformat) {

  'use strict';


  exports['default'] = toFixed;
  function toFixed(value, precision) {
    precision = utils.checkPrecision(precision, settings.number.precision);
    var power = Math.pow(10, precision);

    // Multiply up by precision, round accurately, then divide and use native toFixed():
    return (Math.round(unformat['default'](value) * power) / power).toFixed(precision);
  }

});